import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import AddCustomerModal from "../modals/addCustomerModal";
import AddCompanyModal from "src/modals/addCompanyModal";
import EditCustomerModal from "../modals/editCustomerModal";
import { LoginContext } from "../context/loginContext";
import classes from "./css/senderAndRecipient.module.css";

import CustomSelect from "../components/UI/select";

const SenderAndRecipient = (props) => {
  const [addCustomerModalActive, setAddCustomerModalActive] = useState(false);
  const [addCompanyModalActive, setAddCompanyModalActive] = useState(false);
  const [editCustomerModalActive, setEditCustomerModalActive] = useState(false);
  const [EHFTooltipActive, setEHFToolTipActive] = useState(false);

  const { t } = useTranslation();

  const { dispatch, autoFocus } = props;
  const {
    EHF,
    setEHF,
    userData,
    businessPartnersData,
    selectedBP,
    setSelectedBP,
    selectedClient,
    setSelectedClient,
    setSelectedItem,
    setBusinessPartnersData,
    requestItemDataHandler,
    requestBusinessPartnersHandler,
  } = useContext(LoginContext);

  const selectClientHandler = (event) => {
    localStorage.setItem("selected-client", JSON.stringify(event));
    setSelectedClient(event);
    setSelectedBP({});
    setBusinessPartnersData([]);
    setSelectedItem({});
    dispatch({ type: "RESET" });
    requestItemDataHandler(event);
    requestBusinessPartnersHandler(event);
  };

  let accountOptions = [];

  if (userData !== undefined && userData !== null) {
    accountOptions = userData.map((account) => ({
      value: account.id,
      label: account.display_name,
      address: account.organization.address,
      city: account.organization.city,
      country: account.organization.country,
      vat_liable: account.organization.vat_liable,
    }));
  }

  let businessPartnerOptions = [];

  if (businessPartnersData.length > 0) {
    const customers = businessPartnersData.filter(
      (BP) =>
        BP.statistics.is_customer === true ||
        BP.statistics.is_supplier === false
    );
    businessPartnerOptions = customers.map((BP) => ({
      value: BP.id,
      label: BP.name,
      country: BP.country,
      city: BP.city,
      address: BP.address,
      email: BP.email,
      ehf: BP.sending_methods.ARCRN,
      line1: BP.address,
      line2: BP.email,
    }));
  }

  let BPInfo;
  let BPEmail = "";
  let EHFIndicator = false;

  if (businessPartnersData.length > 0 && selectedBP.value !== undefined) {
    BPInfo = businessPartnersData.find(
      (account) => account.id === selectedBP.value
    );

    if (BPInfo.sending_methods.ARCRN.length > 0) {
      const sendingMethod = BPInfo.sending_methods.ARCRN.find(
        (sendingMethod) => sendingMethod.method === "peppol"
      );
      EHFIndicator = false;
      if (sendingMethod) {
        EHFIndicator = true;
        setEHF(true);
      } else {
        EHFIndicator = false;
        setEHF(false);
      }
    }
    BPEmail = BPInfo.email;
  }

  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>{t("from")}</h3>
      <h3 className={classes.heading}>{t("invoiceFor")}</h3>
      <div>
        <CustomSelect
          onClick={() => setAddCompanyModalActive(true)}
          onChange={selectClientHandler}
          options={accountOptions}
          width={"95%"}
          height={"50px"}
          borderRadius={"10px"}
          placeholder=""
          value={selectedClient}
          autoFocus={autoFocus}
          noOptions={t("noCompanyOptions")}
          type="button"
        >
          {t("addNewCompany")}
        </CustomSelect>
        <div className={classes.emailandpencil}></div>
      </div>
      <div>
        <CustomSelect
          onClick={() => setAddCustomerModalActive(true)}
          onChange={setSelectedBP}
          options={businessPartnerOptions}
          width={"95%"}
          height={"50px"}
          borderRadius={"10px"}
          value={selectedBP}
          onEdit={() => setEditCustomerModalActive(true)}
          noOptions={t("noCustomerOptions")}
          type="multi-line"
        >
          {t("addNewCustomer")}
        </CustomSelect>
        <div className={classes.emailandpencil}>
          <p className={classes.email}>{BPEmail}</p>
          {EHFIndicator && (
            <div
              className={classes.ehf}
              onMouseEnter={() => setEHFToolTipActive(true)}
              onMouseLeave={() => setEHFToolTipActive(false)}
            >
              EHF
              {EHFTooltipActive && (
                <div className={classes.tooltip}>
                  <p>
                    Denne boksen indikierer at kunden kan motta EHF og at
                    Snapbooks vil sende faktura på dette formatet.
                  </p>
                  <p>
                    Lurer du på hva EHF er? Les mer her:
                    <a href="https://snapbooks.no/ehf" target="_blank">
                      {" "}
                      https://snapbooks.no/ehf
                    </a>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {addCustomerModalActive && (
        <AddCustomerModal onClose={() => setAddCustomerModalActive(false)} />
      )}
      {addCompanyModalActive && (
        <AddCompanyModal onClose={() => setAddCompanyModalActive(false)} />
      )}
      {editCustomerModalActive && (
        <EditCustomerModal
          isOrganization={BPInfo.is_organization}
          onClose={() => setEditCustomerModalActive(false)}
        />
      )}
    </div>
  );
};

export default SenderAndRecipient;
