import axios from "axios";
import { useRef, useState, createContext } from "react";

export const LoginContext = createContext({
  requestLoginHandler: () => {},
  requestTokenHandler: () => {},
  requestOTPHandler: () => {},
  logoutHandler: () => {},
  setSelectedClient: () => {},
  requestItemDataHandler: () => {},
  setDateIssued: () => {},
  setDueDate: () => {},
  setExtraComment: () => {},
  setPOReference: () => {},
  setItemData: () => {},
  setSelectedItem: () => {},
  setSelectedBP: () => {},
  requestBusinessPartnersHandler: () => {},
  sendInvoiceRequest: () => {},
  setYourReference: () => {},
  setToken: () => {},
  setUserData: () => {},
  setLoggedIn: () => {},
  setLoginModalActive: () => {},
  setNonce: () => {},
  setLoginOrOTP: () => {},
  setEmailInput: () => {},
  setBusinessPartnersData: () => {},
  setFileList: () => {},
  setFirstName: () => {},
  setLastName: () => {},
  setUserId: () => {},
  setNoCompany: () => {},
  setEHF: () => {},
});

const date = new Date();

const { REACT_APP_PRODUCTION, REACT_APP_STAGING } = process.env;
console.log(process.env);

const LoginContextProvider = (props) => {
  const [emailInput, setEmailInput] = useState("");
  const [loginOrOTP, setLoginOrOTP] = useState(0);
  const [nonce, setNonce] = useState("");
  const [userId, setUserId] = useState();
  const [loginModalActive, setLoginModalActive] = useState(false);
  const OTPInput = useRef(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [token, setToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [noCompany, setNoCompany] = useState(false);

  const [dateIssued, setDateIssued] = useState(new Date());
  const [dueDate, setDueDate] = useState(date.setDate(date.getDate() + 14));
  const [extraComment, setExtraComment] = useState("");
  const [POReference, setPOReference] = useState("");
  const [yourReference, setYourReference] = useState("");

  const [userData, setUserData] = useState();
  const [selectedClient, setSelectedClient] = useState({});
  const [businessPartnersData, setBusinessPartnersData] = useState([]);
  const [selectedBP, setSelectedBP] = useState({});

  const [itemData, setItemData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [EHF, setEHF] = useState(false);

  const URL = REACT_APP_PRODUCTION;

  const requestBusinessPartnersHandler = async (SC, tok) => {
    let ver = token
    if (tok) {
      ver = tok
    }
    
    try {
      setIsLoading(true)
      const response = await axios.get(
        URL + `/api/v1/business-partners?client_account_id=${SC.value}&with=sending_methods,statistics`,
        {
          headers: {
            authorization: ver,
          },
        }
      );
      setIsLoading(false)
      setBusinessPartnersData(response.data.business_partners);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  const requestItemDataHandler = async (SC, tok) => {
    let ver = token;
    if (tok) {
      ver = tok
    }
    try {
      const response = await axios.get(
        URL + `/api/v1/items/?client_account_id=${SC.value}`,
        {
          headers: {
            authorization: ver,
          },
        }
      );
      setItemData(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        dateIssued,
        setDateIssued,
        dueDate,
        setDueDate,
        emailInput,
        setEmailInput,
        loginOrOTP,
        setLoginOrOTP,
        nonce,
        setNonce,
        OTPInput,
        setToken,
        loginModalActive,
        setLoginModalActive,
        token,
        userData,
        selectedClient,
        setSelectedClient,
        requestItemDataHandler,
        extraComment,
        setExtraComment,
        POReference,
        setPOReference,
        setYourReference,
        yourReference,
        itemData,
        setItemData,
        selectedItem,
        setSelectedItem,
        selectedBP,
        setSelectedBP,
        businessPartnersData,
        setBusinessPartnersData,
        requestBusinessPartnersHandler,
        URL,
        setUserData,
        loggedIn,
        setLoggedIn,
        fileList,
        setFileList,
        firstName,
        lastName,
        setFirstName,
        setLastName,
        userId,
        setUserId,
        noCompany,
        setNoCompany,
        isLoading,
        EHF,
        setEHF,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
