import React from "react";
import classes from "./css/input.module.css";

const Input = React.forwardRef((props: any, ref: any) => {

  const {autoFocus, onBlur, onChange, className, placeholder, type, value, disabled, onKeyPress } = props
  return (
    <input
      autoFocus={autoFocus}
      ref={ref}
      onBlur={onBlur}
      onChange={onChange}
      className={`${classes.input} ${className}`}
      placeholder={placeholder}
      type={type}
      value={value}
      disabled={disabled}
      onKeyPress={onKeyPress}
    ></input>
  );
});

export default Input;
