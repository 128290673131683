import { useState } from "react";
import classes from "./css/questionMark.module.css";

const QuestionMark = (props: {
  className: any;
  children: any;
  modalClass: any;
}) => {
  const { className, children, modalClass } = props;

  const [modal, setModal] = useState(false);
  return (
    <div className={`${classes.container} ${className}`}>
      <div
        className={classes.circle}
        onMouseEnter={() => setModal(true)}
        onMouseLeave={() => setModal(false)}
      >
        {modal && (
          <div className={`${classes.modal} ${modalClass}`}>{children}</div>
        )}
        <svg
          className={classes.question}
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6C2 4.88035 2.55827 3.90184 3.49049 3.17383C4.4321 2.43849 5.71321 2 7 2C8.42047 2 9.68595 2.24248 10.5703 2.83205C11.3831 3.37391 12 4.29131 12 6C12 7.12643 11.4956 7.92654 10.7175 8.53331C9.90801 9.16455 8.81629 9.5665 7.7761 9.78674C6.34712 10.0893 5 11.3078 5 12.9922V14C5 14.5523 5.44772 15 6 15C6.55228 15 7 14.5523 7 14V12.9922C7 12.4674 7.45813 11.8984 8.19038 11.7434C9.39238 11.4889 10.809 10.9981 11.9474 10.1105C13.1171 9.19832 14 7.85328 14 6C14 3.70869 13.1169 2.12609 11.6797 1.16795C10.3141 0.257518 8.57953 0 7 0C5.28679 0 3.5679 0.575758 2.25951 1.59754C0.941735 2.62665 0 4.14814 0 6C0 6.55228 0.447715 7 1 7C1.55228 7 2 6.55228 2 6Z"
            fill="white"
          />
          <path
            d="M6 20C6.82843 20 7.5 19.3284 7.5 18.5C7.5 17.6716 6.82843 17 6 17C5.17157 17 4.5 17.6716 4.5 18.5C4.5 19.3284 5.17157 20 6 20Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default QuestionMark;
