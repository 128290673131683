import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    detection: options,
    debug: true,
    resources: {
      en: {
        translation: {
          invoice: "Invoice",
          item: "Item",
          quantity: "Quantity",
          price: "Price",
          VAT: "VAT",
          total: "Total",
          logIn: 'Log In',
          logOut: 'Log Out',

          issued: "Issued",
          dueDate: "Due Date",
          CPA: "Comment, PO and Attachments",

          from: "From",
          invoiceFor: "Invoice For",

          description: "Description (optional)",

          subtotal: "Subtotal",
          amountDue: "Amount Due",
          preview: "Preview",

          searchForYourCompanyAndAddYourBankNumber: "Search for your company, and add your bank account number",
          andName: 'and your name',
          withOrgNr: 'with org nr:',
          searchForYourCompany: 'Search for your company',
          searchForCompany: 'Search for company',

          organizationNumber: 'Organization number',
          email: 'Email',
          postalCode: 'Postal code',
          country: 'Country',
          city: 'City',
          address: 'Address',
          save: 'Save',

          addCustomer: 'Add customer',
          fullName: 'Full name',
          company: 'Company',

          attachments: 'Attachments',
          upploadAttachments: 'Uppload attachments',
          POReference: 'PO reference',
          yourReference: 'Your reference',
          extraComment: 'Extra comment',

          createItem: 'Create item',
          itemNo: 'Item no',
          name: 'Name',
          defaults: 'Defaults',
          unit: 'Unit',
          vatRate: 'Vat rate',

          confirmEmail: 'Please confirm your email',
          aOneTimeCodeHasBeenSentToYourEmail: 'A one time code has been sent to your email, please add your code to the field bellow and click log in',
          invoiceWasSuccessullySent: 'Invoice was successfully sent!!',

          addNew: 'Add new',
          addNewCustomer: 'Add new customer' ,
          addNewCompany: 'Add new company',
          addNewItem: 'Add new item',
          
          bankNumber: 'Banknumber',
          pleaseFillInYourFirstAndLastName: 'Please fill in your first and lastname',
          org: 'org',
          editCustomer: 'Edit customer',
          editItem: 'Edit item',

          invoiceWillBeSentTo: 'Invoice will be sent to',
          by: 'by',
          wouldYouLikeToContinue: 'Would you like to continue?',
          theReciverDoesNotAcceptEHFAndYouHaveNotSpecifiedAnEmail: 'The reciver does not accept EHF, and you have not specified an email',
          continue: 'Continue',

          noCustomerOptions: 'No customer options',
          noCompanyOptions: 'No company options',
          noItemOptions: 'No item options',

          somethingWentWrong: 'Something went wrong!',
          pleaseCheckThatNoFormDataIsMissing: 'Please check that no formdata is missing',
          pleaseSelectAPDFDocument: 'Please select a PDF document and try again',
          failedToLogOut: 'Failed to log out',
          oldDate: "Chosen date is more than 16 workingdays before today's date. This is not in accordance with the invoice standard.",
          
          invalidCode: 'You have entered an invalid code, try again',
          codeHasBeenSent: 'A one time password has been sent to your email',

          thanksForChoosingSnapbooks: 'Thanks for choosing to use Snapbooks invoice which is completely free',

          priceCantBeZero: "The price can't be zero on any of the product lines, please add a price and try again",
          pleaseChooseACustomer: 'Please choose a customer and try again',
          pleaseSelectAQuantity: 'Please select a quantity for each product line and try again',
          pleaseCheckThatTheCustomerEmailAddressIsNotMissing: 'Please check that the customers email address is not missing and try again',
        },
      },


      nb: {
        translation: {
          invoice: "Faktura",
          item: "Vare",
          quantity: "Antall",
          price: "Pris",
          VAT: "MVA",
          total: "Totalt",
          logIn: 'Logg inn',
          logOut: 'Logg Ut',

          issued: "Dato",
          dueDate: "Forfall",
          CPA: "Kommentar, PO og Vedlegg",

          from: "Fra",
          invoiceFor: "Til",

          description: "Beskrivelse (Valgfritt)",

          subtotal: "Sum eks MVA",
          amountDue: "Totalt",
          preview: "Forhåndsvisning",

          searchForYourCompanyAndAddYourBankNumber: "Søk opp firmaet ditt og legg til bankkontonummer",
          andName: 'og navn',
          companyName: 'Firma navn',
          withOrgNr: 'med org nr:',
          searchForYourCompany: 'Søk etter selskapet ditt',
          searchForCompany: 'Søk etter selskap',

          organizationNumber: 'Organisasjons nummer',
          email: 'E-post',
          postalCode: 'Post kode',
          country: 'Land',
          city: 'By',
          address: 'Adresse',
          save: 'Lagre',

          addCustomer: 'Legg til kunde',
          fullName: 'Fullt navn',
          company: 'Selskap',

          attachments: 'Vedlegg',
          upploadAttachments: 'Last opp vedlegg',
          POReference: 'Innkjøpsordrenummer',
          yourReference: 'Din referanse',
          extraComment: 'Ekstra kommentar',

          createItem: 'Legg til vare',
          itemNo: 'Vare nummer',
          name: 'Navn',
          defaults: 'Standard',
          unit: 'Enhet',
          vatRate: 'Momssats',

          confirmEmail: 'Vennligst bekreft epost',
          aOneTimeCodeHasBeenSentToYourEmail: 'En engangs kode har blitt sendt til e-posten din! Vennligst legg inn koden i feltet under og trykk logg inn.',
          invoiceWasSuccessullySent: 'Faktura er sent!',

          addNew: 'Legg til ny',
          addNewCustomer: 'Legg til ny kunde',
          addNewCompany: 'Legg til nytt firma',
          addNewItem: 'Legg til ny vare',
          
          bankNumber: 'Banknummer',
          pleaseFillInYourFirstAndLastName: 'Fyll inn fornavnet og etternavnet ditt!',
          org: 'org',
          editCustomer: 'Rediger kunde',
          editItem: 'Rediger vare',

          invoiceWillBeSentTo: 'Faktura vil bli sendt til',
          by: 'via',
          wouldYouLikeToContinue: 'Vil du fortsette?',
          theReciverDoesNotAcceptEHFAndYouHaveNotSpecifiedAnEmail: 'Mottakeren tar ikke imot EHF og du har ikke spesifisert en epost',
          continue: 'Fortsett',

          noCustomerOptions: 'Ingen kunder enda',
          noCompanyOptions: 'Ingen firma er lagt til enda',
          noItemOptions: 'Ingen varer er lagt til enda',

          somethingWentWrong: 'Noe gikk galt!',
          pleaseCheckThatNoFormDataIsMissing: 'Sjekk at alle de obligatoriske feltene er fylt inn',
          pleaseSelectAPDFDocument: 'Vennligst legg til en PDF og prøv igjen',
          failedToLogOut: 'Klarte ikke å logge ut',
          oldDate: 'Valgt dato er mer enn 16 virkedager før dagens dato. Dette er ikke i henhold til faktura standard.',

          invalidCode: "Du har lagt inn en kode som ikke er gyldig, vennligst prøv igjen.",
          codeHasBeenSent: 'En engangs kode har blitt sendt til eposten din',

          thanksForChoosingSnapbooks: 'Takk for at du vil bruke Snapbooks faktura som er helt gratis!',

          priceCantBeZero: "Pris på en vare kan ikke være 0kr, vennligst legg til en pris og prøv igjen",
          pleaseChooseACustomer: "Vennligst velg en kunde og prøv igjen",
          pleaseSelectAQuantity: "Vennligst velg et antall for hver av varelinjene og prøv igjen" ,
          pleaseCheckThatTheCustomerEmailAddressIsNotMissing: 'Vennligst sjekk om kundens epost adresse mangler og prøv igjen'
        },
      },
    },
  });
