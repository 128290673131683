import { newQueryString } from "./newQueryString";
import { debouncePromise } from "./debouncePromise";
import { fetcher } from "./fetcher";

const getOrgs = (params: string) => fetcher("/organizations/public/" + params);
const getOrgsDebounced = debouncePromise(getOrgs, 300);

export const onOrgNameQueryChange = async (query, setOrgNameQuery, setSelectedOrg, setOrgs, setIsOrgQueryLoading) => {
    try {
      setOrgNameQuery(query);
      if (query === "") setSelectedOrg(undefined);
      if (query.length < 3) return;
      setOrgs(undefined);
      setIsOrgQueryLoading(true);
      const params = newQueryString({
        expanded: "1",
        description: query,
        page: "1",
        per_page: "3",
      });
      if (!params) throw "no params";
      const res = await getOrgsDebounced(params);
      setOrgs(res.organizations);
    } catch (error) {
      console.debug(error);
    } finally {
      setIsOrgQueryLoading(false);
    }
  };
