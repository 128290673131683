import { ReactNode } from "react";
import Button from "./button";
import classes from "./css/error.module.css";

const Error = (props: { onClose?: any; className?: string; children?: ReactNode; }) => {
  const { children, onClose } = props;
  return (
    <div>
      <div className={classes.backdrop} onClick={onClose}></div>
      <div className={`${classes.modal} ${props.className}`}>
        <div className={classes.content}>{children}</div>
        <Button className={classes.button} onClick={onClose}>OK</Button>
      </div>
    </div>
  );
};

export default Error;
