import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./assets/translations/i18n";

import "./assets/styles/global.css";
import App from "./App";
import LoginContextProvider from "./context/loginContext";


Sentry.init({
  dsn: "https://8e20bd8221274c71a076a9624184eec9@o459671.ingest.sentry.io/6623964",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LoginContextProvider>
    <App />
  </LoginContextProvider>
);
