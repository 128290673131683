import { MouseEventHandler } from "react";
import classes from "./css/modal.module.css";

const Modal = (props: { onClose: MouseEventHandler<HTMLDivElement>; className: any; children: any; }) => {
    return (
        <div>
            <div className={classes.backdrop} onClick={props.onClose}>
            </div>
            <div className={`${classes.modal} ${props.className}`}>
                <div className={classes.content}>{props.children}</div>
            </div>
        </div>
    );
};

export default Modal;