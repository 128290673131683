import { MouseEventHandler, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import classes from "./css/createitemModal.module.css";
import Input from "../components/UI/input";
import Modal from "../components/UI/modal";
import Button from "../components/UI/button";
import { LoginContext } from "../context/loginContext";
import QuestionMark from "src/components/UI/questionMark";

import CustomSelect from "../components/UI/select";

const VATOptions = [
  {
    value: 17,
    label: "25%",
  },
  {
    value: 21,
    label: "0 %",
  },
  {
    value: 18,
    label: "15 %",
  },
  {
    value: 19,
    label: "11.11 %",
  },
  {
    value: 20,
    label: "12 %",
  },
  { value: 24, label: "" },
];

const CreateItemModal = (props: {
  onClose: MouseEventHandler<HTMLDivElement>;
  onUpdateForm: any;
  selectedItem: any;
  editing: boolean;
}) => {
  const { selectedClient, requestItemDataHandler, itemData } =
    useContext(LoginContext);
  const { onClose, onUpdateForm, selectedItem, editing } = props;

  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({
    value: 6,
    label: "stk",
  });
  const [selectedVAT, setSelectedVAT] = useState({
    value: 24,
    label: "",
  });

  const { t } = useTranslation();

  const submitHandler = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!editing) {
      const createNewItemRequest = async () => {
        try {
          const response = await axios.post("/api/v1/items", {
            client_account_id: selectedClient.value,
            description: description,
            price: price,
            sales_unit_id: selectedUnit.value,
            tax_code_id: selectedVAT.value,
          });
          requestItemDataHandler(selectedClient);
          console.log(response);
          onClose(undefined);
        } catch (err) {
          console.log(err);
        }
      };
      createNewItemRequest()
    } else if (editing) {
      const itemInfo = itemData.find(
        (item: { id: any }) => item.id === selectedItem.value
      );
      const editItemRequest = async () => {
        e.preventDefault();
        const newItem = {
          ...itemInfo,
          description: description,
          price: price,
          sales_unit_id: selectedUnit.value,
          tax_code_id: selectedVAT.value,
          client_account_id: selectedClient.value,
        };
        const response = await axios.put(`/api/v1/items/${itemInfo.id}`, newItem);
        requestItemDataHandler(selectedClient);
        onUpdateForm({
          ...newItem,
          tax_code: {
            id: selectedVAT.value,
            tax_rate: selectedVAT.label,
          },
        });
        console.log(response);
        onClose(undefined);
      };
      editItemRequest();
    }
  }

  useEffect(() => {
    if (editing) {
      const itemInfo = itemData.find(
        (item: { id: any }) => item.id === selectedItem.value
      );
      setPrice(itemInfo.price);
      setDescription(itemInfo.description);
      setSelectedUnit({
        label: itemInfo.sales_unit.label,
        value: itemInfo.sales_unit.id,
      });
      setSelectedVAT({
        label: itemInfo.tax_code.tax_rate,
        value: itemInfo.tax_code.id,
      });
    }

    axios
      .get("/api/v1/units/")
      .then((response) => {
        console.log(response.data.units);
        setUnits(response.data.units);
      })
      .catch((error) => console.log(error));
  }, [setUnits]);

  let unitOptions = [];

  if (units.length > 0) {
    unitOptions = units.map((unit) => ({
      label: unit.label,
      value: unit.id,
    }));
  }

  return (
    <Modal onClose={props.onClose} className={undefined}>
      <div className={classes.container}>
        <div className={classes.header}>
          {!editing && <h1>{t("createItem")}</h1>}
          {editing && <h1>{t('editItem')}</h1>}
          <QuestionMark
            className={classes.question}
            modalClass={classes.questionmodal}
          >
            <div>
              <p>
                Legg til varenavn og eventuelle standardverdier du ønsker for
                denne varen.
              </p>
              <p>
                Varenummer blir automatisk generert og kommer med på faktura.
              </p>
            </div>
          </QuestionMark>
        </div>
        <form onSubmit={submitHandler}>
          <div className={classes.inputgrid}>
            <p>{t("name")}</p>
            <div>
              <Input
                className={classes.input}
                autoFocus={true}
                value={description}
                onChange={(e: {
                  target: { value: (prevState: undefined) => undefined };
                }) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <h3>{t("defaults")}</h3>
          <div className={classes.inputgrid2}>
            <p>{t("unit")}</p>
            <p>{t("vatRate")}</p>
            <p>{t("price")}</p>
            <CustomSelect
              height="50px"
              borderRadius="10px"
              options={unitOptions}
              onChange={setSelectedUnit}
              width="100%"
              value={selectedUnit}
            ></CustomSelect>
            <CustomSelect
              height="50px"
              borderRadius="10px"
              options={VATOptions}
              onChange={setSelectedVAT}
              width="100%"
              value={selectedVAT}
            ></CustomSelect>
            <div>
              <Input
                className={classes.input}
                type="number"
                value={price}
                onChange={(e: {
                  target: { value: (prevState: undefined) => undefined };
                }) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button className={classes.button} type={"submit"}>
              {!editing &&  t("createItem")}
              {editing && t('save')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateItemModal;
