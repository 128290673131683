import { useContext, useReducer, useEffect, useState } from "react";
import axios from "axios";
import { Worker } from "@react-pdf-viewer/core";
import { useTranslation } from "react-i18next";

import classes from "./App.module.css";
import LoginModal from "./modals/loginModal";
import { LoginContext } from "./context/loginContext";
import DateAndAttachment from "./Views/dateAndAttachment";
import SenderAndRecipient from "./Views/senderAndRecipient";
import ProductLine from "./Views/productLines";
import PreviewAndSend from "./Views/previewAndSend";
import Button from "./components/UI/button";
import Error from "./components/UI/error";

import snapbooks from "./assets/svg/Snapbooks.svg";
import pluss from "./assets/svg/Pluss.svg";
import AddCompanyModal from "./modals/addCompanyModal";
import QuestionMark from "./components/UI/questionMark";
import Redirect from "./redirect";

let isMobile,
  iphone,
  android = false;

if (navigator.userAgent.match("Android")) {
  android = true;
  isMobile = true;
} else if (navigator.userAgent.match("iPhone")) {
  iphone = true;
  isMobile = true;
}

console.log(isMobile);
console.log(navigator);

const { REACT_APP_PRODUCTION, REACT_APP_STAGING } = process.env;

const initialState = [
  {
    ignore_this_id: Math.random(),
    item_id: "",
    item_name: "",
    description: "",
    quantity: "",
    price: "",
    unit_id: "",
    currency_code: "NOK",
    tax_code_id: "",
    tax_rate: "",
  },
];

let index;

const reducer = (state, action) => {
  if (action.payload) {
    index = [...state].findIndex(
      (item) => item.ignore_this_id === action.payload.ignore_this_id
    );
  }
  switch (action.type) {
    case "ADD_LINE":
      return [
        ...state,
        {
          ignore_this_id: Math.random(),
          item_id: "",
          item_name: "",
          description: "",
          quantity: "",
          price: "",
          unit_id: "",
          currency_code: "NOK",
          tax_code_id: "",
          tax_rate: "",
        },
      ];
    case "REMOVE_LINE":
      return [...state].filter(
        (item) => item.ignore_this_id !== action.payload
      );
    case "ITEM":
      state[index] = {
        ...state[index],
        item_no: action.payload.item_no,
        item_name: action.payload.item_name,
        item_id: action.payload.item_id,
        unit_id: action.payload.unit_id,
      };
      return [...state];
    case "QUANTITY":
      state[index] = { ...state[index], quantity: action.payload.quantity };
      return [...state];
    case "PRICE":
      state[index] = { ...state[index], price: action.payload.price };
      return [...state];
    case "VAT":
      state[index] = {
        ...state[index],
        tax_code_id: action.payload.tax_code_id,
        tax_rate: action.payload.tax_rate,
      };
      return [...state];
    case "DESCRIPTION":
      state[index] = {
        ...state[index],
        description: action.payload.description,
      };
      return [...state];
    case "RESET":
      state = [
        {
          ignore_this_id: Math.random(),
          item_id: "",
          item_name: "",
          description: "",
          quantity: "",
          price: "",
          unit_id: "",
          currency_code: "NOK",
          tax_code_id: "",
          tax_rate: "",
        },
      ];
      return state;
    default:
      throw new Error();
  }
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const {
    noCompany,
    setNoCompany,
    loginModalActive,
    setLoginModalActive,
    loggedIn,
    emailInput,
    token,
    userData,
    setUserId,
    setEmailInput,
    setLoginOrOTP,
    setNonce,
    setToken,
    setLoggedIn,
    setSelectedBP,
    setSelectedClient,
    setSelectedItem,
    setDateIssued,
    setDueDate,
    setUserData,
    setBusinessPartnersData,
    setItemData,
    setExtraComment,
    setPOReference,
    setYourReference,
    setFileList,
    setFirstName,
    setLastName,
    requestItemDataHandler,
    requestBusinessPartnersHandler,
  } = useContext(LoginContext);

  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = REACT_APP_PRODUCTION;
  axios.defaults.headers.common["authorization"] = token;

  useEffect(() => {
    let tok = "";
    const refreshRequest = async () => {
      try {
        const response = await axios.post("/api/v1/auth/refresh-token");
        if (
          response.data.user.first_name !== null &&
          response.data.user.last_name !== null
        ) {
          setToken("bearer " + response.data.token);
          setLoggedIn(true);
          setEmailInput(response.data.user.email);
          setUserId(response.data.user.id);
          setFirstName(response.data.user.first_name);
          setLastName(response.data.user.last_name);
        }
        tok = "bearer " + response.data.token;
        const result = await axios.get("/api/v1/client-accounts/", {
          headers: { authorization: tok },
        });
        if (result.data.client_accounts.length !== 0) {
          setUserData(result.data.client_accounts);
          if (result.data.client_accounts.length === 1) {
            const client = result.data.client_accounts[0];
            const clientData = {
              label: client.display_name,
              value: client.id,
              country: client.organization.country,
              city: client.organization.city,
              address: client.organization.address,
              vat_liable: client.organization.vat_liable,
            };
            setSelectedClient(clientData);
            localStorage.setItem("selected-client", JSON.stringify(clientData));
          }
        }
        const client = JSON.parse(localStorage.getItem("selected-client"));
        if (client) {
          setSelectedClient(client);
          requestItemDataHandler(client, tok);
          requestBusinessPartnersHandler(client, tok);
          setNoCompany(false);
        } else {
          setNoCompany(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    refreshRequest();
  }, [
    setToken,
    setUserData,
    setFirstName,
    setLastName,
    setUserId,
    setLoggedIn,
    setEmailInput,
    setNoCompany,
    setSelectedClient,
  ]);

  const logoutHandler = async () => {
    try {
      await axios.post("/api/v1/auth/logout");
      localStorage.removeItem("selected-client");
      setEmailInput("");
      setLoginOrOTP(0);
      setNonce("");
      setToken("");
      setLoggedIn(false);
      setSelectedBP({});
      setSelectedClient({});
      setSelectedItem({});
      setDateIssued(new Date());
      setDueDate(new Date());
      setUserData(undefined);
      setBusinessPartnersData([]);
      setItemData([]);
      setYourReference("");
      setExtraComment("");
      setPOReference("");
      setFileList([]);
      dispatch({ type: "RESET" });
    } catch (error) {
      setError(true);
    }
  };

  let styles = `${classes.button}`;
  if (loggedIn && noCompany && !userData) {
    styles = `${classes.button} ${classes.button2}`;
  }

  return (
    <div className={classes.mainroot}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
        <div className={classes.root}>
          <div className={classes.innerroot}>
            <div className={classes.container}>
              <header className={classes.heading}>
                <div className={classes.empty}></div>
                <div className={classes.logo}>
                  <a href="https://snapbooks.no" target="_blank">
                    <img src={snapbooks} alt="snapbooks" />
                  </a>
                </div>
                <div className={classes.loginandquestion}>
                  <QuestionMark
                    className={classes.question}
                    modalClass={classes.questionmodal}
                  >
                    <p>
                      Snapbooks faktura er et helt gratis komplett
                      fakturaprogram.
                    </p>
                    <p>
                      Send faktura i ditt ønskede format; som epost eller EHF
                    </p>
                    <p>
                      Bekreftelse på utsendt faktura vil bli sendt til din
                      e-post med kopi av faktura og tilhørende vedlegg.
                    </p>
                    <p>
                      Det eneste du trenger for å komme i gang er e-posten din.
                      <p>
                        Vi er selvfølgelig tilgjengelig på chat hvis det er noen
                        spørsmål.
                      </p>
                    </p>
                  </QuestionMark>
                  {!loggedIn && (
                    <div className={classes.buttoncontainer}>
                      <Button
                        className={classes.button}
                        onClick={() => setLoginModalActive(true)}
                      >
                        {t("logIn")}
                      </Button>
                    </div>
                  )}
                  {loggedIn && (
                    <div className={classes.buttoncontainer}>
                      <Button className={styles} onClick={logoutHandler}>
                        {t("logOut")}
                      </Button>
                    </div>
                  )}
                </div>
              </header>
              <div className={classes.wrapper}>
                <p className={classes.invoice}>{t("invoice")}</p>
                <p className={classes.email}>{emailInput}</p>
              </div>
            </div>
            <div className={classes.invoicecontainer}>
              <div className={classes.invoiceInformation}>
                <DateAndAttachment />
                <SenderAndRecipient dispatch={dispatch} />
              </div>
            </div>
            <div className={classes.inputInfo}>
              <div className={classes.item}>
                <p className={classes.text}>{t("item")}</p>
              </div>
              <div className={classes.quantity}>
                <p className={classes.text}>{t("quantity")}</p>
              </div>
              <div className={classes.price}>
                <p className={classes.text}>{t("price")}</p>
              </div>
              <div className={classes.vat}>
                <p className={classes.text}>{t("VAT")}</p>
              </div>
              <div className={classes.total}>
                <p className={classes.text}>{t("total")}</p>
              </div>
            </div>
            <div className={classes.instances}>
              {state.map((item, index) => (
                <ProductLine
                  key={item.ignore_this_id}
                  id={item.ignore_this_id}
                  index={index}
                  onRemoveInvoice={() =>
                    dispatch({
                      type: "REMOVE_LINE",
                      payload: item.ignore_this_id,
                    })
                  }
                  dispatch={dispatch}
                  state={state}
                />
              ))}
            </div>
            <div className={classes.wrap}>
              <div
                className={classes.circle}
                onClick={() => dispatch({ type: "ADD_LINE" })}
              >
                <div className={classes.align}>
                  <img src={pluss} alt="pluss" />
                </div>
              </div>
              <div className={classes.linecontainer}>
                <p className={classes.line}></p>
              </div>
            </div>
            <PreviewAndSend state={state} />
            {loginModalActive && (
              <LoginModal onClose={() => setLoginModalActive(false)} />
            )}
          </div>
          {!loggedIn && (
            <div
              onClick={() => setLoginModalActive(true)}
              className={classes.backdrop}
            ></div>
          )}
          {loggedIn && noCompany && !userData && (
            <AddCompanyModal
              onDismount={() => setNoCompany(false)}
              onClose={() => {}}
            />
          )}
          {error && (
            <Error onClose={() => setError(false)}>
              <p>{t("failedToLogOut")}</p>
            </Error>
          )}
        </div>
      </Worker>
    </div>
  );
};

export default App;
