import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";

import classes from "./css/datePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";

import pencil from "../../assets/svg/Pencil.svg";

const DatePicker = (props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={classes.calendarbutton} onClick={onClick} ref={ref}>
      <div className={classes.buttoncontainer}>
        <div className={classes.date}>{value} </div>
        <div className={classes.buttonicon}>
          <img src={pencil} alt="pencil" />
        </div>
      </div>
    </button>
  ));
  return (
    <ReactDatePicker
      selected={props.selected}
      onChange={props.onChange}
      customInput={<ExampleCustomInput />}
      dateFormat="dd.MM.yyyy"
    />
  );
};

export default DatePicker;