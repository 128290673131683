import classes from "./css/button.module.css";

const Button = (props) => {
  const { className, onClick, onKeyPress, type, children } = props;
  return (
    <button
      className={`${classes.button} ${className}`}
      onClick={onClick}
      onKeyPress={onKeyPress}
      type={type || "button"}
      >
      {children}
    </button>
  );
};


export default Button;
