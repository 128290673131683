import { useState, useContext, MouseEventHandler, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Modal from "../components/UI/modal";
import Button from "../components/UI/button";
import Input from "../components/UI/input";
import classes from "./css/addCompanyModal.module.css";
import { LoginContext } from "../context/loginContext";
import QuestionMark from "src/components/UI/questionMark";

import { ComboInput } from "../components/combo/ComboInput";
import { Organization } from "../components/combo/Organization";
import { onOrgNameQueryChange } from "../components/combo/onOrgNameQueryChange";
import ErrorModal from "src/components/UI/error";

const AddCompanyModal = (props: {
  onClose: MouseEventHandler<HTMLDivElement>;
  onDismount: MouseEventHandler<HTMLDivElement>;
}) => {
  const [orgs, setOrgs] = useState<Organization[]>();
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [orgNameQuery, setOrgNameQuery] = useState("");
  const [isOrgQueryLoading, setIsOrgQueryLoading] = useState(false);

  const [bankNumber, setBankNumber] = useState(0);

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    userId,
    setUserData,
    setSelectedClient,
    loginOrOTP,
    setFirstName,
    setLoggedIn,
    setLoginModalActive,
    setLastName,
    setLoginOrOTP,
    setNoCompany,
  } = useContext(LoginContext);
  const { t } = useTranslation();

  console.log(orgs, selectedOrg, orgNameQuery);

  const onQueryChange = (query: any) => {
    onOrgNameQueryChange(
      query,
      setOrgNameQuery,
      selectedOrg,
      setOrgs,
      setIsOrgQueryLoading
    );
  };

  const addCompanyRequest = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let errorResponse = { message: "" };
    let err = false;
    try {
      const nameHandler = async () => {
        try {
          if (first === "" || last === "") {
            errorResponse = {
              message: "Fyll inn fornavn og etternavn i feltene under",
            };
            throw new Error();
          }
          const response = await axios.put(`/api/v1/users/${userId}`, {
            first_name: first,
            last_name: last,
          });
          console.log(response);
          setFirstName(first);
          setLastName(last);
          setLoggedIn(true);
          setLoginModalActive(false);
        } catch (e) {
          setError(true);
          err = true
          setErrorMessage(errorResponse.message);
        }
      };
      if (loginOrOTP === 2) {
        nameHandler();
      }
      if (err) {
        throw new Error()
      }
      if (bankNumber.toString().length !== 11) {
        errorResponse = {
          message: "Vennligst legg til et gyldig banknummer og prøv igjen",
        };
        throw new Error();
      }
      if (selectedOrg === undefined || null) {
        errorResponse = {
          message: "Vennligst legg til et firma og prøv igjen",
        };
        throw new Error();
      }
      
      let id: any;
      const prevFirms = await axios.get("/api/v1/client-accounts/");
      console.log(selectedOrg, prevFirms);
      const prevComp = prevFirms.data.client_accounts.find(
        (item: any) => item.organization.id === selectedOrg.id
      );
      if (prevComp === undefined) {
        const response = await axios.post("/api/v1/client-accounts/", {
          organization_id: selectedOrg.id,
          first_name: first,
          last_name: last,
        });
        await axios.post(`/api/v1/client-accounts/${response.data.id}/users`, {
          user_id: userId,
          role_id: 3,
        });
        id = response.data.id;
      } else {
        id = prevComp.id;
      }
      await axios.post(
        "/api/v1/bank-accounts/",
        {
          bank_country: selectedOrg.country,
          bban: bankNumber,
          client_account_id: id,
          currency: "NOK",
          is_invoice_account: "D",
          is_payment_account: "D",
          type: "CURRENT",
        },
        {
          transformResponse: (res) => {
            const json = JSON.parse(res);
            errorResponse = { message: json.message };
            return res;
          },
        }
      );
      const accounts = await axios.get("/api/v1/client-accounts/");
      const newAccount = accounts.data.client_accounts.find(
        (account) => account.organization.id === selectedOrg.id
      );
      setSelectedClient({ label: selectedOrg.name, value: newAccount.id });
      setLoginOrOTP(0);
      setUserData(accounts.data.client_accounts);
      setNoCompany(false);
      props.onDismount(undefined);
    } catch (error) {
      setError(true);
      setErrorMessage(errorResponse.message);
    }
  };

  return (
    <Modal onClose={props.onClose} className={classes.modal}>
      <div className={classes.container}>
        <div className={classes.headercontainer}>
          <h2 className={classes.header}>
            {loginOrOTP === 2 && t("thanksForChoosingSnapbooks") + " "}
            {t("searchForYourCompanyAndAddYourBankNumber")}{" "}
            {loginOrOTP === 2 && t("andName")}
          </h2>
          {loginOrOTP === 2 && (
            <div className={classes.questioncontainer}>
              <QuestionMark
                className={classes.question}
                modalClass={classes.questionmodal}
              >
                <p>
                  Siden dette er første gang du er innlogget trenger vi
                  informasjon om selskapet du vil sende faktura fra. Hvis du har
                  flere firma kan du legge til disse senere.
                </p>
                <p>
                  Vi trenger også bedriftens bankkontonummer siden denne skal
                  følge med i utsendt faktura.
                </p>
              </QuestionMark>
            </div>
          )}
        </div>
        <div className={classes.searchcontainer}>
          <p className={classes.selskap}>
            {t("company")}
            <span className={classes.light}>
              {selectedOrg && !isOrgQueryLoading
                ? ` ${t("withOrgNr")}` + selectedOrg.organization_number
                : ""}
            </span>
          </p>
          <div className={classes.search}>
            <ComboInput
              isLoading={isOrgQueryLoading}
              query={orgNameQuery}
              onQueryChange={onQueryChange}
              optionList={orgs}
              selectedOption={selectedOrg}
              onOptionChange={setSelectedOrg}
              placeholder={t("searchForYourCompany")}
              className={classes.comboinput}
            />
          </div>
          <p>{t("bankNumber")}</p>
          <Input
            className={classes.input}
            type={"number"}
            onChange={(e: { target: { value: SetStateAction<number> } }) =>
              setBankNumber(e.target.value)
            }
          />
          {loginOrOTP === 2 && (
            <div className={classes.inputcontainer}>
              <div className={classes.headercontainer}>
                <p className={classes.header}>
                  {t("pleaseFillInYourFirstAndLastName")}
                </p>
              </div>
              <div className={classes.main}>
                <Input
                  type="text"
                  autoFocus={true}
                  className={classes.input}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setFirst(e.target.value)}
                  placeholder="First Name"
                />
                <Input
                  type="text"
                  className={classes.input}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setLast(e.target.value)}
                  placeholder="Last Name"
                />
              </div>
            </div>
          )}
          <Button className={classes.button} onClick={addCompanyRequest}>
            {t("save")}
          </Button>
        </div>
        {error && (
          <ErrorModal onClose={() => setError(false)}>
            {t("somethingWentWrong")}
            <br />
            <br />
            <p className={classes.error}>{errorMessage}</p>
          </ErrorModal>
        )}
      </div>
    </Modal>
  );
};

export default AddCompanyModal;
