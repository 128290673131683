// const baseUrl = 'https://api.staging.snapbooks.com/snapbooks/api/v1'
const baseUrl = 'https://api.snapbooks.com/snapbooks/api/v1'

export const fetcher = async (
  url: string,
  body?: object,
  isNotBase?: boolean,
  token?: string
) => {
  if (!url?.startsWith('/') && !isNotBase) throw 'endpoint must start with /'

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  if (body) {
    options.method = 'POST'
    options.body = JSON.stringify(body)
  }

  const res = await fetch(isNotBase ? url : baseUrl + url, options)

  if (!res.ok) {
    const error = {
      info: await res.json(),
      status: res.status,
      statusText: res.statusText,
    }
    console.log('res is not ok, error: ', error)
    throw error
  }

  return res.json()
}
