import { MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import Button from "../components/UI/button";
import Input from "../components/UI/input";
import Modal from "../components/UI/modal";
import { LoginContext } from "../context/loginContext";
import classes from "./css/loginModal.module.css";

import QuestionMark from "src/components/UI/questionMark";
import Error from "src/components/UI/error";

const overide: object = {
  position: "fixed",
  height: "6rem",
  width: "6rem",
  top: "calc(50% - 3rem)",
  left: "calc(50% - 3rem)",
};

const LoginModal = (props: { onClose: MouseEventHandler<HTMLDivElement> }) => {
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    OTPInput,
    setEmailInput,
    loginOrOTP,
    emailInput,
    setNonce,
    setLoginOrOTP,
    nonce,
    setUserId,
    setToken,
    setNoCompany,
    setLoggedIn,
    setUserData,
    setLoginModalActive,
    setSelectedClient,
    requestItemDataHandler,
    requestBusinessPartnersHandler,
  } = useContext(LoginContext);

  const { t } = useTranslation();

  const requestLoginHandler = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "/api/v1/auth/passwordless?client_id=invoicer",
        {
          email: emailInput,
        }
      );
      setNonce(response.data.nonce);
      setLoginOrOTP(1);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const requestOTPHandler = async () => {
    const OTP = OTPInput.current.value;
    setIsLoading(true);
    try {
      const response = await axios.post(
        "/api/v1/auth/passwordless/login?client_id=invoicer",
        {
          nonce: nonce,
          email: emailInput,
          otp: OTP,
        }
      );
      setUserId(response.data.user.id);
      const tok = "bearer " + response.data.token;
      setToken(tok);
      if (
        response.data.user.first_name === null ||
        response.data.user.last_name === null
      ) {
        setNoCompany(true);
        setLoginOrOTP(2);
        setLoggedIn(true);
      } else {
        const accounts = await axios.get("/api/v1/client-accounts/", {
          headers: {
            authorization: tok,
          },
        });
        console.log(accounts.data.client_accounts.length);
        if (accounts.data.client_accounts.length !== 0) {
          setUserData(accounts.data.client_accounts);
          if (accounts.data.client_accounts.length === 1) {
            console.log(accounts.data.client_accounts);
            const client = accounts.data.client_accounts[0];
            const clientData = {
              label: client.display_name,
              value: client.id,
              country: client.organization.country,
              city: client.organization.city,
              address: client.organization.address,
              vat_liable: client.organization.vat_liable,
            }
            setSelectedClient(clientData);
            localStorage.setItem("selected-client", JSON.stringify(clientData))
          }
        } else {
          setUserData(undefined);
        }
        setLoggedIn(true);
        setLoginModalActive(false);
        const client = JSON.parse(localStorage.getItem("selected-client"));
        if (client) {
          setSelectedClient(client);
          requestItemDataHandler(client, tok);
          requestBusinessPartnersHandler(client, tok);
        } else {
          setNoCompany(true);
        }
      }
      setIsLoading(false);
      props.onClose(undefined);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (loginOrOTP === 0) {
      requestLoginHandler();
    } else if (loginOrOTP === 1) {
      requestOTPHandler();
    }
  };
  const enterHandler = (e) => {
    if (e.charCode === 13) {
      submitHandler(e);
    }
  };

  let content: any;

  if (loginOrOTP === 0) {
    content = (
      <div className={classes.inputcontainer}>
        <div className={classes.headercontainer}>
          <div className={classes.header}>
            <h2 className={classes.login}>{t("logIn")}</h2>
            <div className={classes.questioncontainer}>
              <QuestionMark
                className={classes.question}
                modalClass={`${classes.questionmodal} ${classes.widequestionmodal}`}
              >
                <p>
                  Snapbooks ønsker at alle skal kunne sende ut sin første
                  faktura uten en tung registreringsprosess.
                </p>
                <p>Vi trenger dette for å komme igang:</p>
                <p className={classes.point}>- At du verifserer din e-post</p>
                <p className={classes.point}>
                  - At du velger selskap du vil sende fra{" "}
                </p>
                <p className={classes.point}>
                  - At du legger inn kontonummer hvor du vil ha penger inn
                </p>
                <p className={classes.point}>
                  {" "}
                  - At du legger inn ditt fornavn og etternavn
                </p>
                <p>
                  Når dette er gjort vil du være klar til å bruke Snapbooks
                  fakturamodul - helt gratis.
                </p>
              </QuestionMark>
            </div>
          </div>
          <p>
            Enten du er ny eller eksisterende bruker så trenger vi å få
            verifisert e-posten din.
          </p>
        </div>
        <div className={classes.main}>
          <p className={classes.email}>{t("email")}</p>
          <Input
            type="email"
            autoFocus={true}
            className={classes.input}
            onChange={(e: { target: { value: any } }) =>
              setEmailInput(e.target.value)
            }
            onKeyPress={enterHandler}
          />
        </div>
      </div>
    );
  } else if (loginOrOTP === 1) {
    content = (
      <div className={classes.inputcontainer}>
        <div className={classes.headercontainer}>
          <div className={classes.header}>
            <h2 className={classes.login}>{t("confirmEmail")}</h2>
            <div className={classes.questioncontainer}>
              <QuestionMark
                className={classes.question}
                modalClass={classes.questionmodal}
              >
                <p>{t("codeHasBeenSent")}</p>
              </QuestionMark>
            </div>
          </div>
        </div>
        <div className={classes.main}>
          <p className={classes.text}>
            {t("aOneTimeCodeHasBeenSentToYourEmail")}
          </p>
          <Input
            type="number"
            autoFocus={true}
            className={classes.input}
            ref={OTPInput}
            onKeyPress={enterHandler}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal onClose={props.onClose} className={undefined}>
      {isLoading && <ClipLoader cssOverride={overide} loading={isLoading} />}
      <div className={classes.container}>
        <form onSubmit={submitHandler} className={classes.form}>
          {content}
          <Button className={classes.button} onClick={submitHandler}>
            {t("logIn")}
          </Button>
        </form>
      </div>
      {error && (
        <Error onClose={() => setError(false)}>
          <p>{t("invalidCode")}</p>
        </Error>
      )}
    </Modal>
  );
};

export default LoginModal;
